import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { toast } from 'react-toastify';
import {
  FaArrowLeft, FaArrowRight,
  FaTrash, FaWhatsapp
} from "react-icons/fa";

import {
  Container, InputsContainer, Label,
  Option, Select, SubContainer,
  SelectWrapper, DiaDaSemanaContainer,
  DetalhesContainer, Contato
} from './styles';

import FormInput from '../../components/FormInput';

import api from '../../services/api';
import Context, { IContext } from '../../context/Context';
import { IProfissional } from '../../types/Profissional';
import { IAgendamento } from '../../types/Agendamento';

export default function Controle() {
  const { setIsLoadingOverlay }: IContext = useContext(Context);
  const inputDataRef = useRef<HTMLInputElement>(null);

  const [dat, setDat] = useState(new Date().toISOString().split('T')[0]);
  const [diaDaSemana, setDiaDaSemana] = useState(new Date().toISOString().split('T')[0]);
  const [profissional, setProfissional] = useState<IProfissional[]>([]);
  const [agendamentos, setAgendamentos] = useState<IAgendamento[]>([]);
  const [profissionalSelecionado, setProfissionalSelecionado] = useState('0');

  async function getData() {
    try {
      setIsLoadingOverlay(true);

      const [profissionaisResponse] = await Promise.all([
        api.get('/profissionais'),
      ])

      if (profissionaisResponse.status === 200) {
        setProfissional(profissionaisResponse.data);
      }

    } catch (error) {
      toast.error('Erro ao buscar dados');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function getAgendamentos(data: string) {
    try {
      setIsLoadingOverlay(true);

      const response = await api.get('/agendamentos', {
        params: {
          dat: data,
          ...(profissionalSelecionado && profissionalSelecionado !== '0' && { codpro: profissionalSelecionado })
        }
      })

      if (response.status === 200) {
        if (response.data.length > 1) {
          const agendamentoOrdenadosPorHora = response.data.sort((a: IAgendamento, b: IAgendamento) => {
            return a.horini.localeCompare(b.horini);
          });

          setAgendamentos(agendamentoOrdenadosPorHora);
          return;
        }

        setAgendamentos(response.data);
      }

    } catch (error) {
      toast.error('Erro ao buscar agendamentos');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }


  async function deleteAgendamento(cod?: string | number) {
    try {
      if (!cod) return;
      setIsLoadingOverlay(true);

      await api.delete('/agendamentos/' + cod);
      setAgendamentos(agendamentos.filter(agendamento => agendamento.cod !== cod));

    } catch (error) {
      toast.error('Erro ao deletar agendamento');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }


  function voltarDia() {
    const diaAnterior = new Date(new Date(dat).setDate(new Date(dat).getDate() - 1)).toISOString().split('T')[0];
    setDat(diaAnterior);
    setDiaDaSemana(diaAnterior);
    getAgendamentos(diaAnterior);
  }

  function proximoDia() {
    const diaPosterior = new Date(new Date(dat).setDate(new Date(dat).getDate() + 1)).toISOString().split('T')[0];
    setDat(diaPosterior);
    setDiaDaSemana(diaPosterior);
    getAgendamentos(diaPosterior);
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getAgendamentos(dat);
  }, [profissionalSelecionado]);

  return (
    <Container>
      <h1 translate='no'>Consultar Agenda</h1>
      <SubContainer>
        <DiaDaSemanaContainer>
          <FaArrowLeft
            size={35}
            onClick={() => voltarDia()}
          />
          <h3 translate='no'>
            {new Date(new Date(diaDaSemana).setHours(new Date(diaDaSemana).getHours() + 3)).toLocaleDateString('pt-BR', { weekday: 'long' })}
          </h3>
          <FaArrowRight
            size={35}
            onClick={() => proximoDia()}
          />
        </DiaDaSemanaContainer>
        <InputsContainer>
          <FormInput
            ref={inputDataRef}
            placeHolder=''
            type='date'
            required
            maxLength={14}
            onChange={e => {
              if (isNaN(new Date(e.target.value).getTime())) {
                setDat(new Date().toISOString().split('T')[0]);
                return;
              }

              setDat(e.target.value);
              setDiaDaSemana(e.target.value);
              getAgendamentos(e.target.value);
            }}
            onKeyDown={(e) => e.preventDefault()} // Previne digitação manual
            value={dat}
            onClick={() => inputDataRef?.current && inputDataRef.current.showPicker()}
          />
        </InputsContainer>
        <SelectWrapper>
          <Label translate='no'>Professor(a):</Label>
          <Select value={profissionalSelecionado} onChange={e => setProfissionalSelecionado(e.target.value)}>
            <Option value='0' translate='no'>Qualquer Professor</Option>
            {profissional.map(profissional => (
              <Option key={profissional.cod} value={profissional.cod} translate='no'>{profissional.pro}</Option>
            ))}
          </Select>
        </SelectWrapper>
        {agendamentos.length > 0 && agendamentos.map((agendamento, index) => (
          <DetalhesContainer key={index}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span />
              <h3 translate='no'>{agendamento.horini} - {agendamento.profissional}</h3>
                <FaTrash
                  style={{ cursor: 'pointer', textAlign: 'center', color: '#000', margin: '4px 4px -5px 0px' }}
                  size={15}
                  onClick={() => {
                    if (confirm(`Confirmar cancelamento ${agendamento.horini} - ${agendamento.profissional}?`)) {
                      deleteAgendamento(agendamento.cod);
                    }
                  }}
                />
            </div>
            <p translate='no'>Aluno: {agendamento.nome}</p>
            <p translate='no'>Tipo de Aula: {agendamento.servico}</p>
            <p translate='no'>Ritmo: {agendamento.subservico}</p>
            <Contato>
              <p translate='no'>Contato: {agendamento.cel}</p>
              <FaWhatsapp
                onClick={() => window.open(`https://wa.me/55${agendamento.cel.replace(/\D/g, '')}`, '_blank')}
                size={20}
                style={{ cursor: 'pointer', color: '#25D366', marginLeft: '5px' }}
              />
            </Contato>
          </DetalhesContainer>
        ))}
        {agendamentos.length === 0 && <h3 translate='no'>Nenhuma informação foi encontrada para o filtro informado.</h3>}
      </SubContainer>
    </Container >
  );
}
