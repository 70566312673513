import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import ReactModal from 'react-modal';

import {
  Container,
  InputsContainer,
  Label,
  Option,
  Select,
  SubContainer,
  SelectWrapper,
  TimeButtonContainer,
  TimeButton,
  DiaDaSemanaContainer,
  AlertContainer,
  Message,
  Caption,
  InputsContainerModal,
  ButtonContainer,
  Title,
  ButtonModal
} from './styles';

import FormInput from '../../components/FormInput';
import Button from '../../components/Button';

import Context, { IContext } from '../../context/Context';
import { IHorDis } from '../../types/HorDis';
import { IProfissional } from '../../types/Profissional';
import { formataCelularMask } from '../../utils/formatCelularMask';
import api from '../../services/api';
import { IServico } from '../../types/Servico';
import { ISubServico } from '../../types/SubServico';

export default function Home() {
  const { setIsLoadingOverlay }: IContext = useContext(Context);
  const inputDataRef = useRef<HTMLInputElement>(null);
  const inputNomeRef = useRef<HTMLInputElement>(null);
  const inputCelRef = useRef<HTMLInputElement>(null);
  const pressTimer = useRef<number | null>(null);

  const [dat, setDat] = useState(new Date().toISOString().split('T')[0]);
  const [diaDaSemana, setDiaDaSemana] = useState(new Date().toISOString().split('T')[0]);
  const [profissional, setProfissional] = useState<IProfissional[]>([]);
  const [profissionalSelecionado, setProfissionalSelecionado] = useState('');
  const [horarioSelecionado, setHorarioSelecionado] = useState('');
  const [servicos, setServicos] = useState<IServico[]>([]);
  const [servicoSelecionado, setServicoSelecionado] = useState('');
  const [subServicos, setSubServicos] = useState<ISubServico[]>([]);
  const [subServicoSelecionado, setSubServicoSelecionado] = useState('');
  const [horariosDisponiveis, setHorariosDisponiveis] = useState<string[]>([]);
  const [horDis, setHorsDis] = useState<IHorDis | undefined>();
  const [modalVisible, setModalVisible] = useState(false);

  const [isDebugMode, setIsDebugMode] = useState(false);


  const handleMouseDown = () => {
    pressTimer.current = window.setTimeout(() => {
      setIsDebugMode(!isDebugMode);
    }, 3000); // 3 segundos
  };

  const handleMouseUp = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      pressTimer.current = null;
    }
  };

  const handleMouseLeave = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      pressTimer.current = null;
    }
  };

  async function getData() {
    try {
      setIsLoadingOverlay(true);

      const [profissionaisResponse, servicosResponse] = await Promise.all([
        api.get('/profissionais'),
        api.get('/servicos')
      ])

      if (profissionaisResponse.status === 200) {
        setProfissional(profissionaisResponse.data);
      }

      if (servicosResponse.status === 200) {
        setServicos(servicosResponse.data);
      }

    } catch (error) {
      toast.error('Erro ao buscar dados');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function getSubServicos() {
    try {
      if (!servicoSelecionado) return;
      setIsLoadingOverlay(true);

      const response = await api.get('/subServicos', {
        params: {
          codservico: servicoSelecionado
        }
      });

      if (response.status === 200) {
        setSubServicos(response.data);
      }

    } catch (error) {
      toast.error('Erro ao buscar sub-servicos');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function getHorariosDisponiveis(data: string) {
    try {
      if (!profissionalSelecionado) return;
      setHorarioSelecionado('');

      setIsLoadingOverlay(true);

      const response = await api.get('/horariosDisponiveis', {
        params: {
          dat: data,
          ...(profissionalSelecionado && profissionalSelecionado !== '0' && { codpro: profissionalSelecionado })
        }
      });

      if (response.status === 200) {
        const horariosDisponiveisFiltrados: string[] = [];

        for (const horario of response.data.hordis) {
          if (horariosDisponiveisFiltrados.includes(horario.hordis)) continue;
          horariosDisponiveisFiltrados.push(horario.hordis);
        }

        setHorsDis(response.data);
        setHorariosDisponiveis(horariosDisponiveisFiltrados);
      }

    } catch (error) {
      toast.error('Erro ao buscar horários disponíveis');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
    }
  }

  async function agendarHorario() {
    if (!horDis) return;
    if (!horarioSelecionado) return;
    if (!subServicoSelecionado) return;
    if (!inputCelRef.current?.value) return toast.warning('Preencha o campo de celular');
    if (!inputNomeRef.current?.value) return toast.warning('Preencha o campo de nome');

    try {
      setIsLoadingOverlay(true);
      if(isDebugMode) alert('Começou envio de agendamento');

      const horarioParaAgendar = horDis.hordis.filter(horario => horario.hordis === horarioSelecionado)[0];
      const { hordis, codesp, codpro } = horarioParaAgendar;
      let horFin = (+hordis.split(':')[0] + 1).toString();

      horFin = horFin + ':' + hordis.split(':')[1];

      const payload = {
        cel: inputCelRef.current?.value,
        nome: inputNomeRef.current?.value,
        codpro,
        codesp,
        dat: horDis.dat,
        codsubservico: subServicoSelecionado,
        horini: hordis,
        horfin: horFin
      }

      const response = await api.post('/agendamentos', payload);

      if(isDebugMode) alert('Finalizou POST');

      if (response.status === 201) {
        setDat(new Date().toISOString().split('T')[0]);
        setHorarioSelecionado('');
        setProfissionalSelecionado('');
        setServicoSelecionado('');
        setSubServicoSelecionado('');
        setModalVisible(false)
        toast.success('Agendamento realizado com sucesso!');
      }

    } catch (error: any) {
      if (error.response.data?.erro) {
        toast.error(error.response.data.erro);
        return;
      }

      toast.error('Erro ao agendar horário');
    } finally {
      setTimeout(() => {
        setIsLoadingOverlay(false);
      }, 500);
      if(isDebugMode) alert('Entrou no finally');
      if(isDebugMode) alert('Finalizou agendarHorario()');
    }
  }

  function voltarDia() {
    const diaAnterior = new Date(new Date(dat).setDate(new Date(dat).getDate() - 1)).toISOString().split('T')[0];
    setDat(diaAnterior);
    setDiaDaSemana(diaAnterior);
    getHorariosDisponiveis(diaAnterior);
  }

  function proximoDia() {
    const diaPosterior = new Date(new Date(dat).setDate(new Date(dat).getDate() + 1)).toISOString().split('T')[0];
    setDat(diaPosterior);
    setDiaDaSemana(diaPosterior);
    getHorariosDisponiveis(diaPosterior);
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    agendarHorario();
  }

  function aplicarMascaraCel(e: React.FormEvent<HTMLInputElement>) {
    let input = e.currentTarget;

    if (input.value.includes('+55')) {
      input.value = input.value.replace('+55', '');
    }

    let valorFormatado = formataCelularMask(input.value);

    input.value = valorFormatado;
  };

  function Modal() {
    return (
      <ReactModal
        isOpen={modalVisible}
        appElement={document.getElementById('root') as HTMLElement}
        contentLabel='Modal'
        key='modal-unique-key'
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setModalVisible(false)}
        style={{
          overlay: {
            display: 'flex',
            opacity: 1,
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 99,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <AlertContainer onSubmit={handleSubmit}>
          <Title translate='no'>Confirmar Agendamento</Title>
          <Message translate='no'>{`Deseja Confirmar Agendamento Para o Dia ${new Date(dat).toLocaleDateString('pt-BR', { timeZone: 'UTC' })} às ${horarioSelecionado}?`}</Message>
          <Caption translate='no'>Ao Confirmar Você Está de Acordo com os <a href='https://www.escolaconfrariadadanca.com.br' target='_blank' rel='noreferrer'>Termos e Condições de Uso</a> da Plataforma.</Caption>
          <InputsContainerModal>
            <FormInput
              placeHolder='Nome'
              ref={inputNomeRef}
              defaultValue={''}
              required
            />
            <FormInput
              placeHolder='Celular'
              ref={inputCelRef}
              defaultValue={''}
              onInput={aplicarMascaraCel}
              required
              type='tel'
            />
          </InputsContainerModal>
          <ButtonContainer>
            <ButtonModal
              onClick={() => {
                setModalVisible(false);
              }}
              translate='no'
            >
              Cancelar
            </ButtonModal>
            <ButtonModal
              type='submit'
              translate='no'
            >
              Confirmar
            </ButtonModal>
          </ButtonContainer>
        </AlertContainer>
      </ReactModal>
    )
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getHorariosDisponiveis(dat);
  }, [profissionalSelecionado]);

  useEffect(() => {
    getSubServicos();
  }, [servicoSelecionado])

  return (
    <Container
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseDown}
      onTouchEnd={handleMouseUp}
    >
      <Modal />
      <h1 translate='no'>Agendamento{isDebugMode ? ' - Modo Debug' : ''}</h1>
      <SubContainer>
        <DiaDaSemanaContainer>
          <FaArrowLeft
            size={35}
            onClick={() => voltarDia()}
          />
          <h3 translate='no'>
            {new Date(new Date(diaDaSemana).setHours(new Date(diaDaSemana).getHours() + 3)).toLocaleDateString('pt-BR', { weekday: 'long' })}
          </h3>
          <FaArrowRight
            size={35}
            onClick={() => proximoDia()}
          />
        </DiaDaSemanaContainer>
        <InputsContainer>
          <FormInput
            ref={inputDataRef}
            placeHolder=''
            type='date'
            required
            maxLength={14}
            onChange={e => {
              if (isNaN(new Date(e.target.value).getTime())) {
                setDat(new Date().toISOString().split('T')[0]);
                return;
              }

              setDat(e.target.value);
              setDiaDaSemana(e.target.value);
              getHorariosDisponiveis(e.target.value);
            }}
            onKeyDown={(e) => e.preventDefault()}
            value={dat}
            onClick={() => inputDataRef?.current && inputDataRef.current.showPicker()}
          />
        </InputsContainer>
        {dat &&
          <SelectWrapper>
            <Label translate='no'>Professor(a):</Label>
            <Select value={profissionalSelecionado} onChange={e => setProfissionalSelecionado(e.target.value)}>
              <Option value='' translate='no'>Escolha um Professor(a)</Option>
              <Option value='0' translate='no'>Qualquer Professor</Option>
              {profissional.map(profissional => (
                <Option key={profissional.cod} value={profissional.cod} translate='no'>{profissional.pro}</Option>
              ))}
            </Select>
          </SelectWrapper>}
        {profissionalSelecionado &&
          <SelectWrapper>
            <Label translate='no'>Tipo de Aula:</Label>
            <Select value={servicoSelecionado} onChange={e => setServicoSelecionado(e.target.value)}>
              <Option value='' translate='no'>Escolha o Tipo de Aula</Option>
              {servicos.map(servico => (
                <Option key={servico.cod} value={servico.cod} translate='no'>{servico.servico}</Option>
              ))}
            </Select>
          </SelectWrapper>}
        {servicoSelecionado &&
          <SelectWrapper>
            <Label translate='no'>Ritmo:</Label>
            <Select value={subServicoSelecionado} onChange={e => setSubServicoSelecionado(e.target.value)}>
              <Option value='' translate='no'>Escolha o Ritmo</Option>
              {subServicos.map(subServico => (
                <Option key={subServico.cod} value={subServico.cod} translate='no'>{subServico.subservico}</Option>
              ))}
            </Select>
          </SelectWrapper>}
        {dat &&
          <>
            <h2 translate='no'>{horariosDisponiveis.length > 0 && profissionalSelecionado ? 'Horários Disponíveis' : profissionalSelecionado ? 'Nenhum Horário Disponível' : 'Escolha um Professor'}</h2>
            <TimeButtonContainer>
              {profissionalSelecionado && horariosDisponiveis.map((horario, index) => (
                <TimeButton
                  key={index}
                  onClick={() => {
                    if (isDebugMode) {
                      setHorarioSelecionado(horario);
                      alert('Selecionou horário: ' + horario);
                      return;
                    }

                    setHorarioSelecionado(horario);
                  }}
                  $selected={horarioSelecionado === horario}
                  type='button'
                >
                  {horario}
                </TimeButton>
              ))}
            </TimeButtonContainer>
          </>}
        {horarioSelecionado &&
          <Button
            onClick={() => {
              if (!servicoSelecionado) return toast.warning('Escolha um Tipo de Aula');
              if (!subServicoSelecionado) return toast.warning('Escolha um Ritmo');
              setModalVisible(true)
            }}
            translate='no'
          >
            Agendar
          </Button>}
      </SubContainer>
    </Container>
  );
}
